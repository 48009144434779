import React, { useEffect, useState } from "react";
import { getStaticProps as initProps } from "../../hooks/server.props";
import pageSelector from "../../external/pageSelector";

import AboutUsOne from "@urbanpiper-engineering/meraki-components/dist/Organisms/AboutUsOne";
import AboutUsThree from "@urbanpiper-engineering/meraki-components/dist/Organisms/AboutUsThree";
import AppPromoSection from "@urbanpiper-engineering/meraki-components/dist/Organisms/AppPromoSection";
import FeaturedDishOne from "@urbanpiper-engineering/meraki-components/dist/Organisms/FeaturedDishOne";
import FeaturedDishTwo from "@urbanpiper-engineering/meraki-components/dist/Organisms/FeaturedDishTwo";
import FeaturedDishThree from "@urbanpiper-engineering/meraki-components/dist/Organisms/FeaturedDishThree";
import HeroOne from "@urbanpiper-engineering/meraki-components/dist/Organisms/HeroOne";
import HeroTwo from "@urbanpiper-engineering/meraki-components/dist/Organisms/HeroTwo";
import TestimonialsOne from "@urbanpiper-engineering/meraki-components/dist/Organisms/TestimonialsOne";
import TestimonialsTwo from "@urbanpiper-engineering/meraki-components/dist/Organisms/TestimonialsTwo";
import TestimonialsThree from "@urbanpiper-engineering/meraki-components/dist/Organisms/TestimonialThree";
import HeroThree from "@urbanpiper-engineering/meraki-components/dist/Organisms/HeroThree";
import HowItWorksThree from "@urbanpiper-engineering/meraki-components/dist/Organisms/HowItWorksThree";
import LandingScreenMenu from "@urbanpiper-engineering/meraki-components/dist/Organisms/LandingScreenMenu";
import OurMenuSectionTwo from "@urbanpiper-engineering/meraki-components/dist/Organisms/OurMenuSectionTwo";
import WhyChooseUsSectionTwo from "@urbanpiper-engineering/meraki-components/dist/Organisms/WhyChooseUsSectionTwo";

import { Norican, Oleo_Script_Swash_Caps } from "@next/font/google";
import configSelector from "../../external/configSelector";
import Head from "next/head";
import { useRouter } from "next/router";
import MerakiSDK, {
  ANALYTICS_EVENTS,
} from "@urbanpiper-engineering/meraki-sdk";
import LocationSelector from "../../components/location_selector";
import Image from "next/image";

const f1 = Norican({
  weight: "400",
  style: "normal",
  display: "swap",
  preload: false,
});

const f2 = Oleo_Script_Swash_Caps({
  weight: "400",
  style: "normal",
  display: "swap",
  preload: false,
});

const landingPageComponentsOrder = [
  "heroBanner",
  "aboutUs",
  "brandValues",
  "recommendedItems",
  "menuSection",
  "testimonials",
  "appPromotion",
];

function GetHeroComponent(component: any, variant: string, extras: any) {
  const { customizations: config } = component;
  const { onOffersReadMoreClick } = extras;
  const [coupons, setCoupons] = useState([]);
  const isSearchLocationEnabled = config?.enableLocationSearch;

  useEffect(() => {
    MerakiSDK.getCoupons().then(({ coupons }: any) => {
      setCoupons(coupons.splice(0, 3));
    });
  }, []);

  if (variant === "theme1") {
    return (
      <HeroOne
        title={config.title}
        backgroundImage={config.backgroundImage}
        logo={config.logoUrl || configSelector.getBizLogoURL()}
        locationSearch={isSearchLocationEnabled ? <LocationSelector /> : null}
        imageComponent={Image}
      />
    );
  }

  if (variant === "theme2") {
    return (
      <HeroTwo
        title={config.title}
        subTitle={config.subTitle}
        rightClipBackground={config.backgroundImage}
        topBackground={configSelector.getBizLogoURL()}
        logo={config.logoUrl || configSelector.getBizLogoURL()}
        backgroundImage={config.backgroundImage}
        locationSearch={isSearchLocationEnabled ? <LocationSelector /> : null}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme3") {
    return (
      <HeroThree
        title={config.title}
        subTitle={config?.subTitle}
        subTitleColor={config?.subTitleColor}
        description={config.description}
        featuredImage={config.featuredImage}
        mobileFeaturedImage={config.mobileFeaturedImage}
        backgroundImage={config.backgroundImage}
        offers={coupons}
        logoImage={config.logoUrl || configSelector.getBizLogoURL()}
        locationSearch={isSearchLocationEnabled ? <LocationSelector /> : null}
        onOffersReadMoreClick={onOffersReadMoreClick}
        imageComponent={Image}
      />
    );
  }
  return <></>;
}

const getAboutUsComponent = (component: any, variant: string) => {
  const { customizations: config } = component;

  const ctaURL = config?.ctaUrl;
  if (variant === "theme1") {
    return (
      <AboutUsOne
        title={config.title}
        description={config.description}
        image={config.image}
        onReadMoreClick={() => {
          window.open(ctaURL, "_blank");
        }}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme2") {
    return (
      <AboutUsThree
        onKnowMoreClick={() => {
          window.open(ctaURL, "_blank");
        }}
        firstImage={config.image}
        secondImage={config.imageTwo}
        {...config}
        imageComponent={Image}
      />
    );
  }
  return <></>;
};

function GetRecommendedItems(component: any, variant: string, extras: any) {
  const { customizations: config } = component;

  if (variant === "theme2") {
    return (
      <FeaturedDishTwo
        title={config.title}
        description={config.description}
        onOrderNowClick={() => {
          window.open(config?.ctaUrl, "_blank");
        }}
        image={config?.featuredDishImage}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme3") {
    return (
      <FeaturedDishThree
        title={config.title}
        description={config.description}
        onOrderNowClick={() => {
          window.open(config?.ctaUrl, "_blank");
        }}
        image={config?.featuredDishImage}
        imageComponent={Image}
      />
    );
  }
  return <></>;
}

export const getStaticPaths = async () => ({
  paths: [],
  fallback: true,
});

export const getStaticProps = async (context: any) => {
  const { params, locale } = context;
  const init = await initProps(undefined, locale);
  const landingPageConfig = pageSelector.getLandingPage();
  const seoConfig = landingPageConfig.seo?.[locale] || {};

  return {
    props: {
      ...init.props,
      landingPageConfig,
      seoConfig,
      ...params,
    },
  };
};

const getAppPromotion = (component: any) => {
  const { customizations: config } = component;

  const onAndroidClick = () => {
    window.open(config.androidAppUrl, "_blank");
  };
  const onIOSClick = () => {
    window.open(config.iosAppUrl, "_blank");
  };

  return (
    <AppPromoSection
      onAndroidClick={onAndroidClick}
      onIOSClick={onIOSClick}
      {...config}
      imageComponent={Image}
    />
  );
};

const getTesimonials = (component: any, variant: string) => {
  const { customizations: config } = component;

  if (variant === "theme1") {
    return (
      <TestimonialsOne
        testimonials={config.values.map((e) => ({
          name: e.author_name,
          designation: e.author_position,
          quote: e.content,
          image: e.author_img,
        }))}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme2") {
    return (
      <TestimonialsTwo
        testimonials={config.values.map((e) => ({
          name: e.author_name,
          designation: e.author_position,
          quote: e.content,
          image: e.author_img,
        }))}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme3") {
    return (
      <TestimonialsThree
        testimonials={config.values.map((e) => ({
          name: e.author_name,
          designation: e.author_position,
          quote: e.content,
          image: e.author_img,
        }))}
        imageComponent={Image}
      />
    );
  }
  return <></>;
};

function GetMenuSection(component: any, variant: string, extras: any) {
  const { customizations: config } = component;
  const {
    onViewMenuClickCallback,
    onItemClickCallback,
    menuSectionDisplayNumber,
  } = extras;

  const [items, setItems] = useState([]);
  const dataTransformCategoriesForLandingScreen = (categories: any) => {
    return categories.map((category: any) => {
      let tempCat = category;
      tempCat.item_title = category.name;
      tempCat.image_url = category.image;
      tempCat.item_desc = category.description;
      return tempCat;
    });
  };
  useEffect(() => {
    if (config.mode === "categories") {
      MerakiSDK.getCategories().then((categories: any) => {
        const transformedCategories =
          dataTransformCategoriesForLandingScreen(categories);
        if (menuSectionDisplayNumber === "showAll") {
          setItems(transformedCategories);
        } else {
          setItems(transformedCategories.splice(0, 6));
        }
      });
    } else if (config.mode === "items") {
      MerakiSDK.getRecommendedItems().then((catalogue: any) => {
        setItems(catalogue.splice(0, 6));
      });
    }
  }, [config]);
  if (variant === "theme1") {
    return (
      <FeaturedDishOne
        description={config.description}
        items={items}
        onItemClickCallback={onItemClickCallback}
        viewMoreClickCallback={onViewMenuClickCallback}
        backgroundImage={config.backgroundImage}
        title={config.title}
        imageComponent={Image}
      />
    );
  }

  if (variant === "theme2") {
    return (
      <OurMenuSectionTwo
        onViewMenuClick={onViewMenuClickCallback}
        onItemClickCallback={(item: any) =>
          onItemClickCallback(item, config.mode)
        }
        description={config?.description}
        title={config?.title}
        items={items}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme3") {
    return (
      <LandingScreenMenu
        menu={items}
        onViewMenuClick={onViewMenuClickCallback}
        onItemClick={(item: any) => onItemClickCallback(item, config.mode)}
        description={config?.description}
        title={config?.title}
        imageComponent={Image}
      />
    );
  }
  return <></>;
}

const getLandingPageComponent = (
  component: any,
  theme: string,
  extras: any,
) => {
  if (component?.customizations?.enabled) {
    switch (component._id) {
      case "heroBanner":
        return GetHeroComponent(component, theme, extras);
      case "aboutUs":
        return getAboutUsComponent(component, theme);
      case "brandValues":
        return getWhyChooseUsComponent(component, theme);
      case "appPromotion":
        return getAppPromotion(component);
      case "testimonials":
        return getTesimonials(component, theme);
      case "recommendedItems":
        return GetRecommendedItems(component, theme, extras);
      case "menuSection":
        return GetMenuSection(component, theme, extras);
      default:
        return <></>;
    }
  }
};

const getWhyChooseUsComponent = (component: any, variant: string) => {
  const { customizations: config } = component;

  if (variant === "theme2") {
    return (
      <WhyChooseUsSectionTwo
        onReadMore={() => {
          window.open(config?.readMoreCtaUrl, "_blank");
        }}
        title={config?.title}
        desc={config?.description}
        values={config?.values || []}
        imageComponent={Image}
      />
    );
  }
  if (variant === "theme1" || variant === "theme3") {
    return <HowItWorksThree {...config} imageComponent={Image} />;
  }
  return <></>;
};

function LandingPage({ landingPageConfig, variant, seoConfig }: any) {
  const theme = variant || landingPageConfig?.variant;
  const router = useRouter();

  const componentsConfig = landingPageConfig?.components || [];
  const sortedComponents: any[] = [];

  landingPageComponentsOrder.forEach((component) => {
    const componentConfig = componentsConfig.find(
      (comp: any) => comp._id === component,
    );
    if (componentConfig) {
      sortedComponents.push(componentConfig);
    }
  });
  try {
    MerakiSDK.analyticsPublish(ANALYTICS_EVENTS?.LANDING_SCREEN_OPEN, {
      theme: landingPageConfig?.variant,
    });
  } catch (err) {
    console.log(
      "AnalyticsPublish: ",
      ANALYTICS_EVENTS?.LANDING_SCREEN_OPEN,
      err,
    );
  }

  const onViewMenuClickCallback = () => {
    try {
      const eventObj = {
        variant: landingPageConfig?.variant,
        page_source: "landing_page",
      };
      MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.VIEW_MENU_CLICKED, eventObj);
    } catch (error) {
      console.log(
        "AnalyticsPublish: ",
        ANALYTICS_EVENTS.VIEW_MENU_CLICKED,
        error,
      );
    }
    router.push(`/menu`);
  };

  const onItemClickCallback = (item: any, menuSectionMode: string) => {
    if (menuSectionMode === "categories") {
      router.push(`category/${item.id}/${item?.slug}`);
    } else {
      router.push(`/item-list/${item.id}/${item?.slug}`);
    }
  };

  const onOffersReadMoreClick = () => {
    router.push("/offers");
  };
  let menuSectionDisplayNumber =
    componentsConfig.find((component: any) => component._id === "menuSection")
      ?.customizations?.displayNumber || "default";

  const extras = {
    onViewMenuClickCallback,
    onItemClickCallback,
    onOffersReadMoreClick,
    menuSectionDisplayNumber,
  };

  return (
    <React.Fragment>
      <Head>
        <title>{seoConfig?.title}</title>
        <meta name="description" content={seoConfig?.description} />
        <meta name="keywords" content={seoConfig?.keywords} />
      </Head>
      {sortedComponents.map((component: any) =>
        getLandingPageComponent(component, theme, extras),
      )}
    </React.Fragment>
  );
}

export default LandingPage;
