import {
  DeliveryInfo,
  Location,
} from "@urbanpiper-engineering/meraki-components/dist/types/src/Components/Molecules/FulfillmentSelector/Web/web.interface";
import MerakiSDK, { ANALYTICS_EVENTS, Exceptions } from "@urbanpiper-engineering/meraki-sdk";
import FulfillmentSelector from "@urbanpiper-engineering/meraki-components/dist/Molecules/FulfillmentSelector";
import useAlert from "@urbanpiper-engineering/meraki-components/dist/Molecules/Alert";
import useConfirm from "@urbanpiper-engineering/meraki-components/dist/Molecules/Confirm";
import { FulfillmentMode } from "@urbanpiper-engineering/meraki-sdk/dist/esm/graphql/generated";
import { StoreState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/stores/states/store.state";
import { UserState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/user/states/user.state";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import configSelector from "../../external/configSelector";
import { useIsMobile } from "../../hooks/is_mobile.hook";
import { useRouter } from "next/router";
import { EVENTS, PubSub } from "../../utils/listeners";
import dayjs from "dayjs";
import screenHelper from "../../external/helpers/screenHelper";
import Style from "./location_selector.module.scss";

function LocationSelector({
  businessInfo,
  hideLocationSelectorHeading = false,
}: any) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [stores, setStores] = useState([]);
  const [orderNowLaterData, setOrderNowLaterData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const confirmDialog = useConfirm();
  const alertDialog = useAlert();
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [userLocations, setUserLocations] = useState<Location[]>([]);
  const deliveryPickupConfig = configSelector.getDeliveryPickupConfig();
  const [storeTimings, setStoreTimings] = useState<any>({});
  const router = useRouter();
  const userState = MerakiSDK.getUserState();
  const storeState = MerakiSDK.getStoreState();

  const useUserState = userState.hook();
  const useStoreState = storeState.hook();
  const isLandingPage = screenHelper.isLandingPage(router.pathname);

  const pastLocations = useUserState(
    (state: typeof UserState) => state.pastLocations
  );
  const selectedStore = useStoreState(
    (state: typeof StoreState) => state.selectedStore
  );

  const openDialogAndScrollToTop = () => {
    setShowLocationSelector((prev) => !prev);
    if (!isMobile && screenHelper.isMenuPage(router.pathname)) {
      sessionStorage.setItem("scrolledSoFar", String(window.pageYOffset));
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
    }
  };

  const defaultFulfillmentType = configSelector.getDefaultFulfilmentMode();
  const fulfillmentTypes =
    configSelector.getFulfilmentTypes() as FulfillmentMode[];

  const upFrontDeliveryNowLater =
    configSelector.getUpfrontFulfillmentTimePromptFlag();

  const googleApiKey = configSelector.getGoogleMapsKey();

  useEffect(() => {
    const pubSub = PubSub.getInstance();
    const listener = pubSub.addListener(
      EVENTS.STORE_SELECTION_REQUIRED,
      openDialogAndScrollToTop
    );

    (async () => {
      if (MerakiSDK.isUserAuthenticated() && showLocationSelector) {
        const addresses = await MerakiSDK.getAddresses();
        const filteredAddresses = addresses?.filter((address: any) => {
          return address.tag === "home" || address.tag === "work";
        });

        setUserLocations(
          filteredAddresses.map((address: any) => {
            return {
              address: [address.tag, address.sub_locality].join(", "),
              latitude: address.lat,
              longitude: address.lng,
              placeId: address.id,
              tag: address.tag,
            };
          })
        );
      }
    })();

    return () => pubSub.removeListener(listener);
  }, [showLocationSelector]);

  const onOrderNowLaterRequested = async (storeId: number) => {
    setLoading(true);
    try {
      const storeDeliveryInfo = await MerakiSDK.getStoreDeliveryParams(storeId);
      setOrderNowLaterData(storeDeliveryInfo.order_now_later);
      setStoreTimings({
        opening_time: storeDeliveryInfo?.opening_time || "00:00:00",
        closing_time: storeDeliveryInfo?.closing_time || "00:00:00",
      });
    } catch (err: any) {
      console.log(err);
    }
    setLoading(false);
  };

  const clearAll = () => {
    setLoading(false);
    //setStores([]);
    setShowLocationSelector(false);
    const scrollSoFarOffset = sessionStorage.getItem("scrolledSoFar") || 0;
    if (scrollSoFarOffset) {
      setTimeout(() => {
        window.scrollTo({
          top: parseInt(scrollSoFarOffset),
          left: 0,
          behavior: "smooth",
        });
        sessionStorage.removeItem("scrolledSoFar");
      }, 500);
    }
  };

  const onLocationSelected = async (location: any, ffMode: FulfillmentMode) => {
    setLoading(true);

    try{
      const eventObj = {
        address : location.address,
        latitude : location.latitude,
        longitude : location.longitude,
        placeId : location.placeId,
      }
     MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.ADDRESS_SELECTED, eventObj) 
    }
    catch(error){
      console.log("AnalyticsPublish: ", ANALYTICS_EVENTS.ADDRESS_SELECTED, error)
    }
 
    const stores = await MerakiSDK.getStoresFromLatLng(
      location.latitude,
      location.longitude,
      ffMode
    );

    /** store recently searched locations */
    if (stores?.length > 0) {
      userState.addSelectedLocation(location, ffMode);
      setStores(stores);

      try{
        const eventObj = {
          stores: stores,
          location: location,
        }
        MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES, eventObj)
      }
      catch(error){
        console.log("AnalyticsPublish: ", ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES, error)
      }

    } else {
      setShowLocationSelector(false);

      alertDialog({
        title: t("common.noNearByStoreFoundTitle"),
        content:
          businessInfo?.msg_no_stores_nearby || t("common.noNearByStoreFound"),
      });

      try{
        const eventObj = {
          address : location.address,
          latitude : location.latitude,
          longitude : location.longitude,
          placeId : location.placeId,
        }
        MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.NO_STORES_NEARBY, eventObj) 
      }
      catch(error){
        console.log("AnalyticsPublish: ", ANALYTICS_EVENTS.NO_STORES_NEARBY, error)
      }

    }
    setLoading(false);
  };

  const onStoreSelected = (
    storeId?: number,
    location?: Location,
    ffMode?: any,
    deliveryInfo?: DeliveryInfo
  ) => {
    const storeInfo = stores.find(
      (store: any) => store.biz_location_id === storeId
    );
    const storeContent = {
      id: storeId,
      storeInfo,
      ...location,
      ffMode,
      deliveryInfo,
    };

    try {
      MerakiSDK.setSelectedStore(storeContent);
    } catch (err: any) {
      if (err.message === Exceptions.CART_ALREADY_EXISTS) {
        confirmDialog({
          title: t("header.clearCartHeading"),
          content: t("header.clearCartDescription"),
          onConfirm: () => {
            MerakiSDK.setSelectedStore(storeContent, true);
          },
        });
      }
      if (err.message === Exceptions.STORE_ORDERING_NOT_ENABLED) {
        alertDialog({
          title: t("storeLocator.storeClosed"),
          content:
            businessInfo?.msg_nearest_store_closed ||
            t("storeLocator.storeClosed"),
        });
      }
      if (err.message === Exceptions.STORE_TEMPORARY_CLOSED) {
        alertDialog({
          title: t("storeLocator.storeClosed"),
          content:
            businessInfo?.msg_nearest_store_closed ||
            t("storeLocator.storeClosed"),
        });
      }
    }
  };
  const onLocationStore = () => {
    router.push("/store-locator");
  };

  return (
    <div
      className={
        hideLocationSelectorHeading ? Style.locationSelectorHeadingHidden : ""
      }
      onClick={() => setShowLocationSelector(true)}
    >
      <FulfillmentSelector
        isOpen={showLocationSelector}
        fulfillmentTypes={fulfillmentTypes}
        loading={loading}
        selectedStore={selectedStore}
        onClose={clearAll}
        onLocationSelected={onLocationSelected}
        onStoreSelected={onStoreSelected}
        stores={stores}
        googleApiKey={googleApiKey}
        isMobile={isMobile}
        fulfillmentType={selectedStore?.ffMode || defaultFulfillmentType}
        pastLocations={pastLocations}
        onOrderNowLaterRequested={onOrderNowLaterRequested}
        upFrontDeliveryNowLater={upFrontDeliveryNowLater}
        orderLaterData={orderNowLaterData}
        startDate={dayjs().format("YYYY-MM-DD")}
        endDate={dayjs()
          .add(configSelector.getMaxPreOrderDate(), "days")
          .format("YYYY-MM-DD")}
        userLocations={userLocations}
        isOrderNowDisabled={deliveryPickupConfig?.hideDeliverPickupNow}
        isOrderLaterDisabled={
          deliveryPickupConfig?.maxPreOrderDate === -1 ||
          configSelector.getHideOrderingTime()
        }
        storeTimings={storeTimings}
        onLocationStore={onLocationStore}
        searchMode={isLandingPage}
      />
    </div>
  );
}

export default LocationSelector;
